import Accordion from 'accordion-js';
import domReady from '@wordpress/dom-ready';

domReady(() => {
  const accordionsContainer = Array.from(
    document.querySelectorAll('.accordion-container')
  );
  new Accordion(accordionsContainer, {
    duration: 400,
    showMultiple: true,
  });
});
